import { notification } from "antd"

export default function (message: any, type: "success" | "info" | "warning" | "error" = "info", duration = 2) {
	try {
		console.log(message)
		notification[type]({ message: JSON.stringify(message), duration })
	} catch (error) {
		console.warn("Не удалось отобразить лог")
	}
}
