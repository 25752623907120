import { styled } from "styled-components"

export const GamePageWrapper = styled.div`
	display: grid;
	height: 100%;
`
export const GamePageContent = styled.div<{ showGame: boolean }>`
	display: grid;
	height: 100%;
	place-items: center;

	${({ showGame }) =>
		showGame &&
		`
	max-height: 100dvh;
	max-width: 100dvw;
	aspect-ratio: 16 / 9;
	`}
	> canvas {
		object-fit: cover;
		aspect-ratio: 16 / 9;

		min-width: 100%;

		max-height: 100dvh;
		max-width: 100dvw;

		opacity: ${({ showGame }) => (showGame ? "1" : "0")};
		z-index: 3;
	}
`
