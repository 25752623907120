import { styled } from "styled-components"
import BackgroundField from "../../assets/BackgroundField.png"
import BackgroundLoading from "../../assets/BackgroundLoading.webp"

export const GameLayoutWrapper = styled.div`
	display: grid;
	height: 100%;

	max-height: 100dvh;
`

export const GameLayoutContent = styled.div<{ isLoaded: boolean }>`
	background: no-repeat center url(${BackgroundLoading});

	background-clip: padding-box;
	background-attachment: fixed;
	background-size: contain;
	background-color: ${props => props.theme.mainBackgroundColor};

	display: grid;
	place-items: center;

	// Если игра загрузилась
	${({ isLoaded }) => isLoaded && "background-image: none;"}
	grid-template-rows: ${({ isLoaded }) => (isLoaded ? "1fr" : "1fr 150px")};
`

const fieldHeight = "110px"
const fieldMobileHeight = "130px"

export const LoadingBar = styled.div`
	position: relative;
`

export const BorderBlock = styled.div<{ percents: number }>`
	position: relative;

	&:after {
		content: url(${BackgroundField});
		position: absolute;
		left: 1%;
		top: 0;
		max-width: 98%;
		overflow: hidden;
		height: ${fieldHeight};
		width: ${({ percents }) => percents}%;
	}

	& > img {
		max-height: ${fieldHeight};

		@media only screen and (max-device-width: 480px) {
			max-height: ${fieldMobileHeight};
		}
	}
`

export const Percentages = styled.div`
	position: absolute;
	top: 0;

	height: ${fieldHeight};
	width: 100%;

	display: grid;
	place-items: center;

	font-size: min(10vw, 64px);

	color: white;
	cursor: pointer;

	@media only screen and (max-device-width: 480px) {
		height: ${fieldMobileHeight};
		font-size: min(10vw, 94px);
	}
`

export const LogoutButton = styled.div`
	position: absolute;
	padding: 15px;
	color: blue;
	font-size: 20px;
	font-weight: 700;

	border: 3px solid purple;
	border-radius: 10px;

	top: 20px;
	left: 20px;

	cursor: pointer;
	user-select: none;
`

export const UserMode = styled.div`
	position: absolute;
	color: white;

	font-size: 20px;
	font-weight: 700;

	top: 20px;
	right: 20px;
`
