import fast_debug from "src/utils/fast_debug"
import { useAppDispatch, useAppSelector } from "../../state/hooks/redux"
import { AuthPageContent, AuthPageWrapper, CenterBlock, EnterAsGuest, Logo, SignIn, BorderBlock } from "./styles"
import { useNavigate } from "react-router-dom"
import { logout, registerGuest } from "src/state/reducers/auth.slice"
import { useEffect } from "react"
import LogoImage from "../../assets/Logo.png"
import BorderField from "../../assets/BorderField.png"

const AuthPage = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const { user, isSuccess } = useAppSelector(state => state.authReducer)

	const handleClickGuest = () => {
		// запрос на создание анонимного пользователя
		dispatch(registerGuest())
	}

	const handleClickSignUp = () => {
		// редирект на логин/пароль
		navigate("/signin")
	}

	// useEffect(() => {
	// 	dispatch(logout())
	// }, [])

	useEffect(() => {
		if (isSuccess) {
			navigate("/")
		}
	}, [isSuccess])

	return (
		<AuthPageWrapper>
			<AuthPageContent>
				<Logo>
					<img src={LogoImage} alt="" />
				</Logo>
				<CenterBlock>
					<BorderBlock>
						<img src={BorderField} alt="" />
					</BorderBlock>
					<EnterAsGuest onClick={handleClickGuest}>ENTER AS A GUEST</EnterAsGuest>
					<SignIn onClick={handleClickSignUp}>SIGN IN</SignIn>
				</CenterBlock>
				<div></div>
			</AuthPageContent>
		</AuthPageWrapper>
	)
}

export default AuthPage
