import Cookies from "js-cookie"
import { useEffect } from "react"
import { Unity, UnityConfig, useUnityContext } from "react-unity-webgl"
import AppSlice from "src/state/reducers/app.slice"
import { useAppDispatch, useAppSelector } from "../../state/hooks/redux"
import { GamePageContent, GamePageWrapper } from "./styles"

const GamePage = () => {
	const dispatch = useAppDispatch()
	const { user, tokens } = useAppSelector(state => state.authReducer)
	const { loadingPercent } = useAppSelector(state => state.appReducer)

	const config: UnityConfig = {
		loaderUrl: user?.version?.url + "/Build.loader.js",
		dataUrl: user?.version?.url + "/Build.data.gz",
		frameworkUrl: user?.version?.url + "/Build.framework.js.gz",
		codeUrl: user?.version?.url + "/Build.wasm.gz",
		streamingAssetsUrl: user?.version?.url + "/StreamingAssets",
		companyName: "Qubix",
		productName: "Qubix",
		productVersion: "0.1",
		webglContextAttributes: {
			powerPreference: "high-performance",
		},
		cacheControl: () => "must-revalidate",
	}

	const { unityProvider, isLoaded, loadingProgression, sendMessage, addEventListener, unload } = useUnityContext(config)

	useEffect(() => {
		const formatedProgress = +loadingProgression.toFixed(2) * 100
		if (formatedProgress !== loadingPercent) {
			dispatch(AppSlice.actions.setLoadingPercent(formatedProgress))
		}
	}, [loadingProgression])

	if (!user?.version?.url) {
		return <div>User no have version</div>
	}

	window.getToken = () => {
		return Cookies.get("access_token") || tokens.access_token
	}

	return (
		<GamePageWrapper>
			<GamePageContent showGame={!!isLoaded}>
				<Unity className="unity" unityProvider={unityProvider} />
			</GamePageContent>
		</GamePageWrapper>
	)
}

export default GamePage
